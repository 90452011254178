import { useState, useEffect, useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import './index.scss'
import blackLogo from '@/imgs/new/logo.svg'
import whiteLogo from '@/imgs/new/whiteLogo.svg'
import { GlobalContext } from '@/App.js'
import Dsr from '@/imgs/new/dsr.png'
import XClose from '@/imgs/new/xClose.png'
import RightArrowIcon from '@/imgs/new/RightArrowIcon.svg'

const Header = () => {
    const { headerTheme } = useContext(GlobalContext)
    const [linkList, setLinkList] = useState([
        {
            label: '首页',
            link: '/'
        },
        {
            label: '产品介绍',
            link: '/CoreTechnology'
        },
        {
            label: '行业方案',
            link: '/CoreProduct'
        },
        {
            label: '关于我们',
            link: '/AboutUs'
        },
        {
            label: '加入我们',
            link: '/ContactUs'
        }
    ])

    const [fontSize, setFontSize] = useState('16px')
    const routerLocation = useLocation()

    // 监听屏幕大小
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 920) {
                setFontSize('16px')
            } else {
                setFontSize('10px')
            }
        }
        handleResize()
        linkList.map((item) => {
            if (item.link === routerLocation.pathname && item.link !== '/') {
                console.log('routerLocation.pathname:', routerLocation)
                document.title = `${item.label} | Autoagents`
            }
        })
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    const [isScrollyZero, setIsScrollyZero] = useState(true)

    useEffect(() => {
        // let lastScrollY = window.scrollY;
        // console.log(lastScrollY, 'lastScrollY')

        const handleScroll = () => {
            const currentScrollY = window.scrollY
            console.log(currentScrollY, 'currentScrollY')
            if (currentScrollY > 70) {
                setIsScrollyZero(false)
            } else {
                setIsScrollyZero(true)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleOpenFeiShu = () => {
        window.open(
            'https://uxkpl4cba3j.feishu.cn/share/base/form/shrcnfPbghOCjQU7EKfR8DloiNc',
            '_blank'
        )
    }

    const handleNavLinkClick = (label) => {
        window.scrollTo(0, 0)
        if (label === '首页') {
            document.title = `北京未来式智能科技有限公司 | AutoAgents.ai | AI Agents 大语言模型场景化落地产品服务提供商`
        } else {
            document.title = `${label} | Autoagents`
        }
    }

    const [isDsr, setIsDsr] = useState(true)

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {isDsr ? (
                <div
                    style={{
                        height: '69px',
                        position: 'relative',
                        overflow: 'hidden'
                    }}>
                    <img
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            objectFit: 'cover'
                        }}
                        src={Dsr}
                        alt=""
                    />
                    <img
                        onClick={() => setIsDsr(false)}
                        src={XClose}
                        alt=""
                        style={{
                            position: 'absolute',
                            width: '16px',
                            height: '16px',
                            top: '29px',
                            right: '51px',
                            cursor: 'pointer',
                            zIndex:999
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0 20px',
                            zIndex: 1
                        }}>
                        <div
                            className="dsr-container"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft:'15vw'
                            }}>
                            <svg
                                style={{ marginRight: '12px' }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="33"
                                viewBox="0 0 27 33"
                                fill="none">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M26.9069 7.98557L13.3457 0.347656L0.228516 7.99934V24.4049L13.3455 32.2127L26.9069 24.4188V7.98557ZM2.9508 9.56293L13.3694 3.4854L24.1846 9.5767V22.8435L13.3696 29.059L2.9508 22.8573V9.56293ZM6.68082 12.3738V8.59125L12.2846 11.8134V21.1997L6.68082 24.2818V20.7794L9.34261 19.0983V13.6346L6.68082 12.3738ZM20.55 12.3738V8.59125L14.9462 11.8134V21.1997L20.55 24.2818V20.7794L17.8882 19.0983V13.6346L20.55 12.3738Z"
                                    fill="white"
                                />
                            </svg>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                <span className="dsr-title">
                                    元知 —— DeepSeek满血R1 研究无忧
                                </span>
                                <span className="dsr-subTitle">
                                    新一代AI深度研究工具，让复杂分析更稳定、更高效
                                </span>
                            </div>
                        </div>
                        <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight:'15vw'
                        }}
                        className="dsr-button"
                        onClick={() =>
                            window.open(
                                'https://deepchat.agentspro.cn/',
                                '_blank'
                            )
                        }>
                        立即体验
                        <img src={RightArrowIcon} alt="" />
                    </div>
                    </div>
                    
                </div>
            ) : null}
            <div
                className="page-header"
                style={
                    headerTheme === 'dark'
                        ? isDsr
                            ? isScrollyZero
                                ? {
                                      backdropFilter: 'blur(15px)',
                                      WebkitBackdropFilter: 'blur(15px)',
                                      backgroundColor:
                                          'rgba(255, 255, 255, 0.2)',
                                      top: '69px'
                                  }
                                : {
                                      backdropFilter: 'blur(15px)',
                                      WebkitBackdropFilter: 'blur(15px)',
                                      backgroundColor:
                                          'rgba(255, 255, 255, 0.2)',
                                      top: '0px'
                                  }
                            : {
                                  backdropFilter: 'blur(15px)',
                                  WebkitBackdropFilter: 'blur(15px)',
                                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                  top: '0px'
                              }
                        : isDsr
                        ? isScrollyZero
                            ? {
                                  backdropFilter: 'blur(0)',
                                  WebkitBackdropFilter: 'blur(0)',
                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                  top: '69px'
                              }
                            : {
                                  backdropFilter: 'blur(0)',
                                  WebkitBackdropFilter: 'blur(0)',
                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                  top: '0px'
                              }
                        : {
                              backdropFilter: 'blur(0)',
                              WebkitBackdropFilter: 'blur(0)',
                              backgroundColor: 'rgba(255, 255, 255, 0)',
                              top: '0px'
                          }
                }>
                <div className="logo-box">
                    <img
                        className="logo"
                        src={headerTheme === 'dark' ? blackLogo : whiteLogo}
                        alt="logo"
                    />
                </div>
                <div className="title-link-box">
                    {linkList.map((item) => {
                        return (
                            <NavLink
                                key={item.label}
                                className={({ isActive, isPending }) =>
                                    isPending
                                        ? 'pending'
                                        : isActive
                                        ? 'active title-link'
                                        : 'title-link'
                                }
                                to={item.link}
                                onClick={() => handleNavLinkClick(item.label)}
                                style={
                                    headerTheme === 'dark'
                                        ? {
                                              fontSize: fontSize,
                                              color: '#170F49'
                                          }
                                        : {
                                              fontSize: fontSize,
                                              color: '#ffffff'
                                          }
                                }>
                                {item.label}
                            </NavLink>
                        )
                    })}
                    <span
                        className="title-link title-btn"
                        style={{ fontSize: fontSize }}
                        onClick={handleOpenFeiShu}>
                        联系我们
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Header
